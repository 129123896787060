// Build information, automatically generated by `ng-info`
const build = {
    version: "4.10.0",
    timestamp: "Thu Nov 21 2024 17:07:14 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "master",
        hash: "a067d2",
        fullHash: "a067d21282d92b512f41680fa1b9e604c37b0452"
    }
};

export default build;